/**
 * Override any slipway theme items here
 */

import Logo from './logo.inline.svg'
import LogoMobile from './logo-mobile.inline.svg'

export default {
  Logo,
  LogoMobile
}
