import React, { ReactNode } from 'react'
import { Link, navigate } from 'gatsby'
import { LinkProvider, MediaSizeProvider } from '@bestyled/contrib-common'
import Helmet from 'react-helmet';

import themeSlipway from '../src/styles/theme'

import { createElement } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const cwdTheme = require('@content/theme/index').default
import _ from 'lodash'
import { AppWrapper, useAppContext } from '../src/utils/global-context';
import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'

const Env = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : process.env.NODE_ENV


interface GatsbyRootProps {
  element: ReactNode
}

const theme = _.merge(themeSlipway, cwdTheme)

export const wrapRootElement = (
  gatsbyRootProps: GatsbyRootProps
  // pluginOptions: { uitheme?: any }
) => {
  _.extend(theme, cwdTheme)

  if ( typeof window !== 'undefined' && window.document) {
    Sentry.init({
      dsn: 'https://ce64e87d4d114559adbf82441647ceee@o452213.ingest.sentry.io/6144615',
      integrations: [
        new CaptureConsole({
          levels: ['error']
        })
      ],
      sampleRate: 0.7,
      environment: Env,
      maxBreadcrumbs: 50,
      enabled: /stag|prod/.test(Env),

    })
  } 
  // @ts-ignore

  // Insert React context helpers here
  return (
    <AppWrapper>
      <Helmet>
        <meta httpEquiv='Cache-Control' content='max-age: 0, no-cache, no-store, must-revalidate, s-maxage=0' />
        <meta httpEquiv='Pragma' content='no-cache' />
        <meta httpEquiv='Expires' content='0' />
        <meta httpEquiv='Vary' content='*' />
        <meta httpEquiv='X-Content-Type-Options' content='nosniff' />
      </Helmet>
      <StyledThemeProvider theme={theme}>
        <LinkProvider Link={Link} navigate={navigate}>
          <MediaSizeProvider>{gatsbyRootProps.element}</MediaSizeProvider>
        </LinkProvider>
      </StyledThemeProvider>
    </AppWrapper>
  )
}

export const StyledThemeProvider = ({ children, theme }) => {
  // @ts-ignore
  const [context = {theme: null}] = useAppContext()
  const {theme: theme2} = context
  console.debug('CONTEXT THEME', context)
  return (
    <ThemeProvider theme={!theme2 ? theme : theme2}>
      {children}
      <BodyStyle key='theme-body' />
    </ThemeProvider>
  )
}

const BodyStyle = createGlobalStyle<{ theme: any }>`
body {
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.background};
}
`
