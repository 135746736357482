// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-all-specialists-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/AllSpecialists.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-all-specialists-template-tsx" */),
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-article-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/article.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-article-template-tsx" */),
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-landing-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/landing.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-landing-template-tsx" */),
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-page-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/page.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-page-template-tsx" */),
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-specialist-booking-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/specialist-booking.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-specialist-booking-template-tsx" */),
  "component---slipway-theme-packages-slipway-spa-theme-src-templates-specialist-template-tsx": () => import("./../../../slipway-theme/packages/slipway-spa-theme/src/templates/specialist.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-spa-theme-src-templates-specialist-template-tsx" */)
}

