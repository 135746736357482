import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext({})

export const state = (state ) => state

export function AppWrapper({ children }) {
  // Use reducers instead
  const [state, setstate] = useState({theme:null})

  return <AppContext.Provider value={[state, setstate]}>{children}</AppContext.Provider>
}

export function useAppContext(): [any, (any)=>any] {
  return useContext(AppContext) as [any, (any) => any]
}
